import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'

import Logotype from 'images/logotype.svg'
import SiteMetadata from 'queries/SiteMetadata'

const Header = () => {
  const { smallHeader, largeHeader } = useStaticQuery(
    graphql`
      query {
        smallHeader: file(
          relativePath: {
            eq: "aurelien-lemasson-theobald-UgZ7z4lRilc-unsplash-small.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              layout: FULL_WIDTH
              transformOptions: { cropFocus: NORTH }
            )
          }
        }
        largeHeader: file(
          relativePath: {
            eq: "aurelien-lemasson-theobald-UgZ7z4lRilc-unsplash.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              layout: FULL_WIDTH
              transformOptions: { cropFocus: NORTH }
            )
          }
        }
      }
    `
  )
  const { name } = SiteMetadata()

  const sources = [
    getImage(smallHeader),
    {
      ...getImage(largeHeader),
      media: '(min-width: 600px)',
    },
  ]

  return (
    <oma-grid-row fullWidth paddingOnChildren>
      <BgImage image={sources}>
        <header className='header page__column'>
          <Link to='/'>
            <img
              src={Logotype}
              alt={`Logotyp för ${name}`}
              width='200'
              height='112'
            />
          </Link>
          <p className='header__text'>
            Elinstallationer för både privatpersoner och företag
          </p>
        </header>
      </BgImage>
    </oma-grid-row>
  )
}

export default Header
