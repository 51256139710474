import { graphql, useStaticQuery } from 'gatsby'

const SiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          city
          email
          name
          organisationNumber
          phoneNumber
          streetAddress
          zipCode
          clerkId
          facebookId
          instagramId
        }
      }
    }
  `)

  return site.siteMetadata
}

export default SiteMetadata
