import React from 'react'
import { Layout as HeimrLayout } from '@accodeing/gatsby-theme-heimr'

import Footer from 'components/Footer'
import Header from 'components/Header'
import siteImage from 'images/site-image.jpg'
import 'style/index.scss'

const Layout = ({ children, pageTitle }) => (
  <HeimrLayout
    pageTitle={pageTitle}
    siteImageRelativePath={siteImage}
    fontAwesome={true}
    omaComponents={[
      'grid@0.7.0',
      'grid-row@0.7.0',
      'link@0.7.0',
      'social-media-links@0.7.0',
    ]}
  >
    <oma-grid>
      <Header />

      {children}

      <Footer />
    </oma-grid>
  </HeimrLayout>
)

export default Layout
