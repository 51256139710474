import React from 'react'
import { Link } from 'gatsby'
import { CreatedBy } from '@accodeing/gatsby-theme-heimr'
import SiteMetadata from 'queries/SiteMetadata'

const Footer = () => {
  const {
    email,
    facebookId,
    instagramId,
    name,
    phoneNumber,
    organisationNumber,
    streetAddress,
    city,
    zipCode,
  } = SiteMetadata()

  return (
    <oma-grid-row fullwidth paddingOnChildren>
      <footer className='footer page__column'>
        <div className='footer__columns'>
          <div>
            <h3>Länkar</h3>
            <span>
              <Link to='cookies' className='link'>
                Cookies
              </Link>
            </span>
          </div>
          <div className='contacts'>
            <h3>{name}</h3>
            <span>
              {streetAddress}, {zipCode} {city}
            </span>
            <span>
              <oma-link to={phoneNumber} class='link' />
            </span>
            <span>
              <oma-link to={email} class='link' />
            </span>
            <span>{organisationNumber}</span>
          </div>
          <oma-social-media-links class='social-media-links'>
            <oma-social-media-link
              accountid={facebookId}
              label='Följ oss på Facebook'
              type='facebook'
              target='_blank'
              rel='noreferrer'
              class='social-media-links__item'
            >
              <span className='fab fa-facebook-f social-media-links__icon'></span>
            </oma-social-media-link>
            <oma-social-media-link
              accountid={instagramId}
              label='Följ oss på Instagram'
              type='instagram'
              target='_blank'
              rel='noreferrer'
              class='social-media-links__item'
            >
              <span className='fab fa-instagram social-media-links__icon'></span>
            </oma-social-media-link>
          </oma-social-media-links>
        </div>
        <div className='credits'>
          <CreatedBy />
          <p>
            Fotot i sidhuvudet från{' '}
            <a href='https://unsplash.com/@aurel__lens' className='link'>
              Aurélien Lemasson-Théobald
            </a>{' '}
            på{' '}
            <a href='https://unsplash.com/' className='link'>
              Unsplash
            </a>
          </p>
        </div>
      </footer>
    </oma-grid-row>
  )
}

export default Footer
